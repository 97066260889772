
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
import { roles } from "@/store/stateless/store";

interface IPayload {
  type: string;
  per_page: number | string;
  page: number;
}

export default defineComponent({
  name: "quote-other-requests-list",
  data() {
    return {
    };
  },
  setup() {
    const store = useStore();
    const currentPage = ref(1);

    let bodyParameters = ref<IPayload>({
      type: 'quote',
      per_page: 50,
      page: 1
    });

    let exportUrl = ref<string>("");

    const myPagination = computed(() => {
      return store.getters.getCallBackExportPagination;
    });

    onMounted(() => {
      if(!roles.inquireGetQuote.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Get Quotes (Others)", ["Inquires"]);
      getCallBackRequestList(bodyParameters);
    });

    const getCallBackRequestList = (bodyParameters) => {
      console.log(bodyParameters.value);
      MasterService.getCallbackRequestList(bodyParameters.value);
    };

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.page = currentPage.value;
      getCallBackRequestList(bodyParameters);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.value.per_page = event.target.value;
      getCallBackRequestList(bodyParameters);
    };

    const exportCallbackRequest = () => {
      Promise.all([
        MasterService.exportCallbackRequest(bodyParameters.value)
      ]).then(() => {
        let exportData = computed(() => {
          return store.getters.getExportData;
        });
        exportUrl.value = exportData.value;
      });
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const search = ref<string>("");

    return {
      roles,
      currentPage,
      myPagination,
      search,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      exportUrl,
      disableSaveButton,
      exportCallbackRequest,
    };
  },
  methods: {
    indexMethod(index) {
      return (
        this.myPagination.current_page * this.myPagination.per_page -
        this.myPagination.per_page +
        index +
        1
      );
    }
  }
});
